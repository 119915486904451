
.item-list {
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */
  /* margin: auto 0; */
    /* overflow: auto; */
    height: 80%;
    width: 100%;
    overflow-y: scroll;
    float: left;
    position: relative;
    margin-left: -5px;
    padding: 0 2em 2em 2em;
}

.item-container {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 2em;
    padding: 1em 0 1em 0;
    border-bottom: 1px #c8cbfc solid;
}

.add-item-box {
    background: rgb(230,230,230);/*#99aedc;#c8cbfc;*/
    color: black;
    margin-left: 2em;
    margin-top: 1em;
    margin-bottom: 1em;
    display: flex;
    align-items: center;
    /* border-radius: 10px; */
    padding: 5px;
    width: 75%;
    min-height: 3em;
}
  

.add-item-box svg {
    margin: 5px;
}
  
.add-item-input {
    box-sizing: border-box;
    border: none;
    background: transparent;
    color: black;
    width: 75%;
    height: 30px;
}
  
input::placeholder {
    color: #ec645b;
}

input:focus {
    outline: 0;
}

.tutor-list-header-container {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 4em;
    padding: 2em 0 1em 0;
    border-bottom: 1px #c8cbfc solid;
}

.tutor-list-header {
    display: inline-flex;
    width: 65%;
    text-align: left;
    font-size: .8em;
}

.item-info {
    display: inline-flex;
    width: 75%;
    text-align: left;
    font-size: .8em;
}

.placement-list-name {
    width: 50%;
}

.placement-list-rate {
    width: 20%;
}

.placement-list-start {
    width: 35%;
}

.placement-list-subject {
    width: 15%;
}

.placement-list {
    width: 20%;
}

.placement-whole {
    display: flex;
    height: 80%;
}

.placement-right-side {
    width: 60%;
    height: 100%;
    border-left: 1px #c8cbfc solid;

}

.placement-left-side {
    /* display: inline-grid;
    justify-content: center; */
    width: 40%;
    height:100%;
}

.invoice-thirds {
    width: 33%;
    border-left: 1px #c8cbfc solid;
    color: black;
}

.invoice-title {
    margin-bottom: 2em;
}

.invoice-detail {
    font-size: .85em;
}

.invoice-history-table {
    width: 80%;
    margin: 0 auto;
    padding: 1em;
}

.invoice-history-row {
    display: flex;
}

.invoice-history-column {
    width: 50%;
    font-size: .85em;
    text-align: left;
}

.placement-select-tutor-div {
    height: 15%;
    width: 90%
}

.invoice-date {
    width: 70%;
    margin: 0 auto;
    padding: 0.5em;
}

.number-tutors {
    text-align: left;
    padding-left: 25%;
}

.tutor-list-header {
    display: inline-flex;
    width: 75%;
    text-align: left;
    font-size: .8em;
}
