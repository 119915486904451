.overlay {
    position: fixed;
    display: block; 
    overflow: auto; 
    width: 100%; 
    height: 100%; 
    top: 0; 
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5); 
    z-index: 999; 
    cursor: pointer;
  }

.content {
        margin: 15% auto;
        background-color: white;
        border-radius: 0.25rem;
        width: 50vw;
        padding: 2rem;
        position: relative;
  }  

.error-modal {
    /* position: absolute; */
    /* text-align: center; */
    /* margin: auto; */
    background-color: white;
    margin-top: 10px;
    width: 300px;
    height: 100px;
    color: red;
    font-size: 18px;
    font-weight: 500;
    /* display: flex; */
    /* flex-direction: column; */
    border-radius: 5px;
    /* opacity: 0; */
    /* pointer-events: none; */
    transform: translateY(-275px) translateX(300px);
    transition: all 0.4s ease;
    /* padding: 0; */
    z-index:1;
    /* list-style: none; */
    /* opacity: 1; */
    /* pointer-events: fill; */
    /* position: absolute; */
    /* justify-content: space-around; */
    /* align-items: center; */
    /* overflow-x: hidden; */
    /* overflow-y: auto; */
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
}

.error-text {
    margin-bottom: 20px;
}