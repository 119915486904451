
.mobile-create-group-div{
    width: 90%;
    margin: 0 auto;
    padding: 20px;
}

.mobile-login-field{
    width: 90%;
    margin: 0 auto;
    padding: 20px;
}

/* .mobile-create-course-field{
    border: 2px solid lightgrey;
    border-radius: 5px;
    font-size: 16px;
    width: 100%;
} */
