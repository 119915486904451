* {
    box-sizing: border-box;
}

.col-1 {width: 8.33%;}
.col-2 {width: 16.66%;}
.col-3 {width: 25%;}
.col-4 {width: 33.33%;}
.col-5 {width: 41.66%;}
.col-6 {width: 50%;}
.col-7 {width: 58.33%;}
.col-8 {width: 66.66%;}
.col-9 {width: 75%;}
.col-10 {width: 83.33%;}
.col-11 {width: 91.66%;}
.col-12 {width: 100%;}

[class*="col-"] {
    float: left;
    padding: 15px;
    border: 1px solid red;
  }

.header-container {
    background-color: var(--main-bg-color);
    color: var(--main-color);
    width: 100vw;
    min-height: 10vh;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    display: inline-flex;
}

.header-row-1 {width: 20%;}
.header-row-2 {width: 50%;}
.header-row-3 {width: 20%;}

:root{
    --main-bg-color: #2a57a7;
    --main-color: white;
}

/* .main-color{
    background-color: #2a57a7;
    color: white;
}

.reverse-color{
    color: #2a57a7;
    background-color: white;
} */

body{ 
    background-color: var(--main-color);
    color: var(--main-bg-color);
    height: 100%;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight:normal;
    line-height: 1.6em;
    margin: 0;
    text-align: center;
  }

  /* input[type="text"], input[type="password"], input[type="time"] {
    -webkit-box-shadow: 
      inset 0 0 8px  rgba(0,0,0,0.1),
            0 0 16px rgba(0,0,0,0.1); 
    -moz-box-shadow: 
      inset 0 0 8px  rgba(0,0,0,0.1),
            0 0 16px rgba(0,0,0,0.1); 
    box-shadow: 
      inset 0 0 8px  rgba(0,0,0,0.1),
            0 0 16px rgba(0,0,0,0.1); 
    padding: 5px;
    margin: 0 0 10px 0;
    text-align: center;
} */

a{
    cursor: pointer;
    color: #000;
    /* font-weight: bold; */
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

/* .select-text {
    cursor: pointer;
    font-weight: 500;
} */

.body-container {
    height: 90%;
}

.attendance-container{
    width: 100%;
    display: inline-block;
}

.log-in{
    height: 100%;
    width: 100%;
    display: inline-block;
}

.login-container{
    width: 100%;
    display: inline-block;
}

.login-container button {
    color: white;
    border-radius: 0px;
    margin: 0px;
    padding: 2px;
    width: 49%;
    height: 3em;
    font-size: 18px;
    border: 2px solid white;
}

.login-container input{
    margin: 20px 0;
}

.login-field{
    width:50%;
    margin: 0 auto;
}

.login-container a{
    color: #a50fe1;
    text-decoration: none;
    cursor: pointer;
}

.login-container a:hover{
    text-decoration: underline;
}

/* .create-course{
    height: 100%;
    width: 100%;
    display: inline-block;
} */

.create-course-field{
    border: 2px solid lightgrey;
    border-radius: 5px;
    font-size: 16px;
    width: 100%;
}

.pay-rate {
    border: 2px solid lightgrey;
    border-radius: 5px;
    font-size: 16px;
    width: 100px;
}

/* table{
    border-collapse: collapse;
    margin: 25px auto 25px auto;
    font-size: 0.9em;
    font-size: 14px;
    font-family: sans-serif;
    min-width: 80vw;
    max-width: 100vw;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    color: #000;
    text-align: left;
}

thead{
    background-color: var(--main-bg-color);
    color: var(--main-color);
    font-size: 16px;
}

th, td{
    padding: 12px 30px;
}

table tbody tr{
    border-bottom: 1px solid #9a9a9a;
}

table tbody tr td a {
    text-decoration: none;
    font-size: 14px;
    font-weight: 700;
    color: #000;
} */

table tr:nth-child(odd){
    /* background-color: #e3e4e8; */
}

/* table tr:nth-child(even){
    background-color: #f1f1f1;
} */



/* button{
color: var(--main-color);
background: var(--main-bg-color);
  width: 125px;
  height: 35px;
  margin: 0.5em 1em 0.5em 0;
  border-radius: 18px;
  border: none;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
  font: 14px/100% Arial, Helvetica, sans-serif;
  border: solid 1px var(--main-bg-color);
  background: -webkit-gradient(linear, left top, left bottom, from(#faa51a), to(#f47a20));
  background: -moz-linear-gradient(top,  #faa51a,  #f47a20);
  filter:  progid:DXImageTransform.Microsoft.gradient(startColorstr='#faa51a', endColorstr='#f47a20');
} */

.btn-all{
    width: 125px;
    height: 35px;
    margin: 0.5em 1em 0.5em 0;
    border-radius: 18px;
    border: none;
    padding: 0;
    cursor: pointer;
    outline-offset: 4px;
    font: 14px/100% Arial, Helvetica, sans-serif;
    border: solid 1px var(--main-bg-color);
    background: -webkit-gradient(linear, left top, left bottom, from(#faa51a), to(#f47a20));
    background: -moz-linear-gradient(top,  #faa51a,  #f47a20);
    filter:  progid:DXImageTransform.Microsoft.gradient(startColorstr='#faa51a', endColorstr='#f47a20');
}

.btn-user-info {
    border-radius: 50%;
    border: 3px solid #333;
    /* opacity: 0.5; */
    width: 50px;
    height: 50px;
}

.btn-sign-out {
    width: 100%;
    height: 25px;
    /* margin: 0.5em 1em 0.5em 0; */
    border-radius: 18px;
    border: none;
    padding: 0;
    cursor: pointer;
    outline-offset: 4px;
    font: 14px/100% Arial, Helvetica, sans-serif;
    border: solid 1px var(--main-bg-color);
    background: -webkit-gradient(linear, left top, left bottom, from(#faa51a), to(#f47a20));
    background: -moz-linear-gradient(top,  #faa51a,  #f47a20);
    filter:  progid:DXImageTransform.Microsoft.gradient(startColorstr='#faa51a', endColorstr='#f47a20');
}

.create-group-add-btn{
    font-weight: 700;
    font-size: 20px;
}

.create-group-students{
    display: flex;
    flex-direction: column;
}

.select-ttutor-date {
    width: 90%;
    margin: auto;
}

.filter-attendance{

    width: 90%;
    margin: auto;
    padding: 1em;
}

.modify-attendance-field{
    display: flex;
}

.modify-attendance-block{
    margin: auto;
    width: 70%;
}

.student-attendance{
    display: flex;
}

.student-attendance div{
    /* margin-right: 20px; */
    width: 150px;
}

.create-group-div{
    width: 50%;
    margin: 0 auto;
    padding: 20px;
}

.create-invoice-div {
    padding-bottom: 15px;
    width: 70%;
    display: block;
    margin: 20px auto 20px auto;
}

/* .attendance-select{
    width: 70%;
    display: block;
    margin: 20px auto 20px auto;
} */

.userEmail{
    font-size: 12px;
}

/* .table-wrapper{
    display: flex;
    overflow-x: auto;
} */

/* .select-text {
    cursor: pointer;
    padding: 10px;
} */

.flex-block {
    display: flex;

}

.option-block {
    display: block;
}

.select-pair {
    display: flex;
    margin-bottom: 20px;
}

.login-page {
    height: 100%;
    width: 100%;
    display: inline-block;
    background-image: url(../../public/images/rm373batch13-085.jpg);
    background-size: 100% 100%;
}

.login-card {
    background-color: #fff;
    border: 2px solid blue;
    border-radius: 10px;
    max-width: 375px;
    min-width: 375px;
    min-height: 550px;
    max-height: 550px;
    margin: 3em auto;
}

.login-card-mobile {
    background-color: #fff;
    border: 2px solid blue;
    border-radius: 10px;
    max-width: 300px;
    min-width: 300px;
    min-height: 500px;
    max-height: 500px;
    margin: 3em auto;
}

.login-title {
    margin-top: 40px;
    font-size: 32px;
}

.total_payout {
    color: black;
    font-size: 14px;
    margin-top: 20px;
}

.tutorInfo {
    color: black;
    font-size: 12px;
    display: flex;
    text-align: center;
    margin: auto 0;
    justify-content: space-evenly;
}

.tutorItem {
    color: black;
    text-align: left;
}

.analytics-charts {
    width: 90%;
    height: 100%;
    margin: auto;
    padding-top: 40px;
    padding-bottom: 40px;
}

.analytics-home {
    /* justify-content: center; */
}

.button-nav-group {
    margin-top: 50px;
}

.side-by-side {
    display: flex;
    justify-content: center;
}

.times {
    margin: 10px;
}

.break-selector {
    padding-left: 10px;
    margin: auto 0;
    text-align: center;
}

.view-timesheet-block {
    display: block;
}

.help-main {
    text-align: left;
    margin-left: 10em;
    font-size: 14px;
}

.help {
    text-align: left;
    margin-top: 1em;
}

.help-tab {
    text-align: left;
    margin-left: 4em;
}

.help-tab2 {
    text-align: left;
    margin-left: 8em;
}

.date-flex {
    display: flex;
}

.day-space {
    margin-right: .5em;
}

.total-payout {
    font-size: 13px;
}

.btn-add-icon {
    padding-right: 2em;
}

.title-bar {
    margin: 1em;
}

.data-input-section {
    height: 85%;
    overflow-y: scroll;
}

.confirm-statement {
    font-size: 1em;
}

.ts-entry-row {
    width: 100%;
    /* display: inline-flex; */
    /* flex-direction: column; */
    margin-bottom: 1em;
}

.solo-name {
    width: 50%;
    margin-top: 1em;
}

.modify-ts-top {
    width: 90%;
    display: inline-flex;
}