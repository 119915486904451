.topbar {
    width: 100%;
    height: 10%;
    background: white;
    position: sticky;
    top: 0;
    z-index: 999;
    border-bottom: 1px solid rgb(205, 205, 215);
}

.topbarWrapper {
    height: 100%;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo {
    font-weight: bold;
    font-size: 30px;
    color: darkblue;
    cursor: pointer;
    display: flex;
}

.topbarIconContainer {
    display: flex;
}

.topbarIcons {
    cursor: pointer;
    padding-right: 20px;
}

.topAvatar {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    cursor: pointer;
}

/* .nav-btn {
    width: 35px;
} */

.dropdown{
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.nav-group{
    /* position: relative; */
}

.dropdown .nav-btn{
    color: #2a57a7;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    width: 35px;
    margin-left:20px;
}

.dropdown button:hover {
    color: lightgrey;
}

.dropdown button:focus + ul{
    opacity: 1;
    pointer-events: fill;
    background-color: white;
    overflow-x: hidden;
    overflow-y: auto;
    transform: translateY(0px) translateX(-140px);
    position: absolute;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
}

/* .nav-group ul {
    position: absolute;
    background-color: white;
    margin-top: 10px;
    width: 200px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    opacity: 0;
    pointer-events: none;
    transform: translateY(-10px);
    transition: all 0.4s ease;
    padding: 0;
    z-index:1;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
}

.nav-group li{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.nav-group li:hover{
    pointer-events: fill;
}

.nav-group li:hover{
    background-color: #b8c5db;
}

.nav-group a{
    color: black;
    text-decoration: none;
} */

.bottom-nav {
    width: 100%;
    height:100px;
    background-color: green;
}

.version{
    font-size: 10px;
    padding-left: 5px;
}

.space {
    padding-left: 10px;
}